import React from "react"

import Seo from "components/seo"
import Layout from "components/layout"
import { Button } from "components/anti/buttons/buttons"

import { Link } from "components/anti/link/link"

const BantuanListTemplate = ({ pageContext }) => {
  const data = pageContext.data
  const helpList = data.helpLists.nodes


  return (
    <Layout>
      <Seo title={data.name} />
      <section className="py-main sc-bantuan-2">
        <div className="container mw-lg">
          <Button
            variant="link"
            className="btn-back-help ai-arrow-from-left"
            link={`/bantuan`}
          >
            BANTUAN
          </Button>
          <h1 className="font-weight-normal">{data.name}</h1>
          <ul>
            {helpList.map((helpData, i) => (
              <Link
                variant="link"
                className="text-dark"
                to={`/bantuan/${data.slug}/${helpData.slug}`}
              >
                <li key={i}>{helpData.title}</li>
              </Link>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default BantuanListTemplate
